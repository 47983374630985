import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { decodeJWT } from "../../utils";
import alertSound from "../../assets/sound/alert-sound.wav";

import { getNotificationsService } from "../../services/userServices";

const NotificationComponent = () => {
  const location = useLocation();
  const userInfo = useSelector((state) => state.userInfo);

  const token = localStorage.getItem("token");
  const decodedToken = decodeJWT(token);

  const [notifications, setNotifications] = useState([]);
  const [shownNotifications, setShownNotifications] = useState(new Map());

  //* Handle Blocked Pages
  const blockedPages = ["/", "/Sign-Up", "/Forget-Password"];
  const isBlockedPages = blockedPages.includes(location.pathname);

  //* Handle Get Notifications
  const handleGetNotification = async () => {
    try {
      const { status, data } = await getNotificationsService();
      if (status === 200) {
        setNotifications(data);
      }
    } catch ({ response }) {
      if (response?.status) {
        // toast.error(response.data.message);
      }
    }
  };

  const handleInitial = async () => {
    if (!isBlockedPages) {
      await handleGetNotification();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleInitial();
    };
    fetchData();

    const interval = setInterval(handleInitial, 2000);
    return () => clearInterval(interval);
  }, [location.pathname]);

  //*
  const playSound = () => {
    const audio = new Audio(alertSound);
    audio.play().catch((error) => {
      console.error("پخش صدا با خطا مواجه شد:", error);
    });
  };

  useEffect(() => {
    if (isBlockedPages) return;

    notifications.forEach((notification) => {
      const { id, transaction_id, tType, customeruser } = notification;
      const key = `${id}-${tType}`;

      if (shownNotifications.has(key)) return;

      if (
        tType === "E" &&
        decodedToken?.usertype === "CUSTOMER" &&
        userInfo[0]?.id === customeruser
      ) {
        playSound();
        toast(`سفارش شما ثبت شد - شماره سفارش: ${transaction_id}`, {
          id: `toast-${key}`,
          duration: 8000,
        });
      }

      if (tType === "E" && decodedToken?.usertype === "ADMIN") {
        playSound();
        toast(`سفارش جدید ثبت شد - شماره سفارش: ${transaction_id}`, {
          id: `toast-${key}`,
          duration: 8000,
        });
      }

      if (
        tType === "T" &&
        decodedToken?.usertype === "CUSTOMER" &&
        userInfo[0]?.id === customeruser
      ) {
        playSound();
        toast.success(`سفارش شما تأیید شد - شماره سفارش: ${transaction_id}`, {
          id: `toast-${key}`,
          duration: 8000,
        });
      }

      if (
        tType === "L" &&
        decodedToken?.usertype === "CUSTOMER" &&
        userInfo[0]?.id === customeruser
      ) {
        playSound();
        toast.error(`سفارش شما لغو شد - شماره سفارش: ${transaction_id}`, {
          id: `toast-${key}`,
          duration: 8000,
        });
      }

      setShownNotifications((prev) => new Map(prev).set(key, true));
    });
  }, [notifications, shownNotifications]);

  return null;
};

export default NotificationComponent;
