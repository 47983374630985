import { useEffect, useState } from "react";

import { HiOutlineNewspaper } from "react-icons/hi";

import { toastErrorMessage } from "../../utils/toastMessage/toastMessage";
import { getNewsPublicService } from "../../services/userServices";
import PreLoader from "../PreLoader/PreLoader";

const NewsModal = ({ viewTag }) => {
  const [getNews, setGetNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //* Handle Get Items List
  const handleGetNews = async () => {
    try {
      const { status, data } = await getNewsPublicService();
      if (status === 200) {
        setGetNews(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetNews();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <span
        className={`lg:absolute top-12 text-white font-normal bg-[#252839]
        ${
          viewTag === "pc" ? "w-full" : "w-[95%]"
        } lg:h-64 h-44 overflow-y-scroll p-2 text-justify rounded-xl
        text-sm border-2 border-[#A79B81] z-50
        animate__animated animate__fadeInDown animate__fast`}
      >
        {isLoading ? (
          <PreLoader width={60} />
        ) : (
          <>
            {getNews?.title === "" ? (
              <span className="w-full flex flex-col items-center justify-center h-full text-slate-200">
                <HiOutlineNewspaper size={40} />
                <span>خبری وجود ندارد</span>
              </span>
            ) : (
              <>
                <span className="animate__animated animate__fadeIn animate__slow">
                  <span className="my-3 block text-base font-bold text-center">
                    {getNews?.title}
                  </span>
                  <span className="my-2 leading-loose">
                    {getNews?.description}
                  </span>
                </span>
              </>
            )}
          </>
        )}
      </span>
    </>
  );
};

export default NewsModal;
