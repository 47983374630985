import http from "./configServices/httpService";

// ProductApi
const apiDomain = "https://api.samarehgolestani.ir"

// DevelopmentApi
// const apiDomain = "https://testapi.samarehgolestani.ir"



//* Start Auth Services
export const singUpAuthService = (data) => {
  return http.post(`${apiDomain}/Auth/register/`, data);
};

export const otpRegisterAuthService = (data) => {
  return http.post(`${apiDomain}/Auth/register/token/`, data);
};

export const logInAuthService = (data) => {
  return http.post(`${apiDomain}/Auth/login/`, data);
};

export const optAuthService = (data) => {
  return http.post(`${apiDomain}/Auth/login/token/`, data);
};

export const forgetPassAuthService = (data) => {
  return http.post(`${apiDomain}/Auth/resetpassword/token/`, data);
};

export const confirmForgetPassAuthService = (data) => {
  return http.post(`${apiDomain}/Auth/resetpassword/`, data);
};
//* End Auth Services

//* Start Change Password Services
export const changePasswordService = (data) => {
  return http.post(`${apiDomain}/Auth/changepassword/token/`, data);
};

export const confirmChangePasswordService = (data) => {
  return http.post(`${apiDomain}/Auth/changepassword/`, data);
};
//* End Change Password Services

//* Start User Info Services
export const getUserInfoServices = () => {
  return http.get(`${apiDomain}/Customer/user/`);
};

//* Start Price Public Services
export const postTransactionPublicService = (data) => {
  return http.post(
    `${apiDomain}/Customer/transaction/`, data);
};

export const getProductsListPublicService = () => {
  return http.get(`${apiDomain}/Public/product/`);
};
//* End Price Public Services

//* Start Live Order Customer Services
export const getLiveOrdersCustomerService = () => {
  return http.get(`${apiDomain}/Customer/liveorder/`);
};

export const getOrdersHistoryCustomerService = async (objData) => {
  try {
    const productType = objData.productType;
    const from = Number(objData.Date[0]);
    const to = Number(objData.Date[1]);
    let url = `${apiDomain}/Customer/transactionhistory/?`;
    if (from && to) {
      url += `from=${from}&to=${to}&`;
    }
    if (productType) {
      url += `tType=${productType}`;
    }

    const response = await http.get(url);
    return response
  } catch (error) {
    //* console.error("Error fetching orders history:", error);
  }
};

//* Start Live Transaction Admin Service
export const getLiveTransactionAdminService = () => {
  return http.get(`${apiDomain}/Admin/liveorder/`);
};

export const changeOrderTypeAdminServices = (data) => {
  return http.patch(
    `${apiDomain}/Admin/liveorder/${data.id}`,
    data
  );
};
//* End Live Transaction Admin Service

//* Start Order History Admin Service
export const getTransactionsHistoryAdminServices = async (objData) => {
  try {
    const productType = objData.productType;
    const from = Number(objData.Date[0]);
    const to = Number(objData.Date[1]);
    let url = `${apiDomain}/Admin/transactionhistory/?`;
    if (from && to) {
      url += `from=${from}&to=${to}&`;
    }
    if (productType) {
      url += `tType=${productType}`;
    }

    const response = await http.get(url);
    return response
  } catch (error) {
    //* console.error("Error fetching orders history:", error);
  }
};

export const changeOrderTypeHistoryServices = (data) => {
  return http.patch(
    `${apiDomain}/Admin/transactionhistory/${data.id}`,
    data
  );
};
//* End Order History Admin Service

//* Start Users Admin Service
export const createUsersAdminService = (data) => {
  return http.post(`${apiDomain}/Admin/user/`, data);
};

export const getUsersAdminService = () => {
  return http.get(`${apiDomain}/Admin/user/`);
};

export const editUserAdminServices = (data) => {
  return http.put(
    `${apiDomain}/Admin/user/${data.id}`,
    data
  );
};

export const changeUserActiveServices = (data) => {
  return http.patch(
    `${apiDomain}/Admin/user/${data.id}`,
    data
  );
};

export const deleteUsersAdminService = (id) => {
  return http.delete(`${apiDomain}/Admin/user/${id}`);
};

//! Create Order By Admin Services
export const createOrderByAdminService = (data) => {
  return http.post(`${apiDomain}/Admin/transactionforeachuser/`, data);
};

//* End Users Admin Service

//* Start Products Admin Service
export const createProductsAdminService = (data) => {
  return http.post(`${apiDomain}/Admin/product/`, data);
};

export const getProductsAdminService = () => {
  return http.get(`${apiDomain}/Admin/product/`);
};

export const getProductAdminService = (id) => {
  return http.get(`${apiDomain}/Admin/product/${id}`);
};

export const editProductAdminServices = (data) => {
  return http.put(`${apiDomain}/Admin/product/${data.id}`, data);
};

export const changeProductActiveServices = (data) => {
  return http.patch(
    `${apiDomain}/Admin/product/${data.id}`,
    data
  );
};
//* End Products Admin Service

//* Start Users Requests Admin Service
export const getUsersRequestAdminService = () => {
  return http.get(`${apiDomain}/Admin/user/acception/`);
};

export const changeUserRequestsAdminServices = (data) => {
  return http.patch(
    `${apiDomain}/Admin/user/${data.id}`,
    data
  );
};

export const deleteUserRequestsAdminServices = (id) => {
  return http.delete(`${apiDomain}/Admin/user/${id}`);
};
//* End Users Requests  Admin Service

//* Start News Public Services
export const getNewsPublicService = () => {
  return http.get(`${apiDomain}/Public/news/`);
};
//* End News Public Services

//* Start News Admin Services
export const createNewsAdminService = (data) => {
  return http.post(`${apiDomain}/Admin/news/`, data);
};

export const getNewsAdminService = () => {
  return http.get(`${apiDomain}/Admin/news/`);
};

export const editNewsAdminServices = (data) => {
  return http.put(
    `${apiDomain}/Admin/news/${data.id}`,
    data
  );
};

export const deleteNewsAdminServices = (id) => {
  return http.delete(`${apiDomain}/Admin/news/${id}`);
};
//* End News Admin Services

//* Start User Tolerance Services
export const getUserToleranceAdminService = async (objData) => {
  try {
    const productName = objData.productName
    const userID = objData.userID;
    let url = `${apiDomain}/Admin/trader/?`;
    if (productName && userID) {
      url += `productID=${productName}&userID=${userID}&`;
    }

    const response = await http.get(url);
    return response
  } catch (error) {
    //* console.error("Error fetching orders history:", error);
  }
};

export const editUserToleranceAdminServices = (data, id) => {
  console.log("object",data);
  return http.put(
    `${apiDomain}/Admin/trader/${id}`,
    data
  );
};
//* End User Tolerance Services

//* Start Store Status Admin Services
export const getGetStoreStatusAdminService = () => {
  return http.get(`${apiDomain}/Admin/storestatus/`);
};

export const changeStoreStatusAdminServices = (data) => {
  return http.patch(
    `${apiDomain}/Admin/storestatus/${data.id}`,
    data
  );
};
//* End Store Status Admin Services

//* Start Notifications Services
export const getNotificationsService = () => {
  return http.get(`${apiDomain}/Public/notif/`);
};
//* End Notifications Services